@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_variables";
@import "../../../../../../profiles/wcmc/themes/wcm_brand_base/scss/_mixins";

/* = Engineering and Maintenance Three Column
----------------------------------------------------------*/

.panel-em-home {
  padding-top: 2rem;

  #top-3col-left {
    padding-left: 0;
    padding-right: 0;
  }

  #top-3col-center {
    padding-left: 0;
    padding-right: 0;

    @include breakpoint($md) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  #top-3col-right {
    padding-left: 0;
    padding-right: 0;
  }

  #em-home-bottom,
  #em-home-footer {
    padding: 0;
    margin: 0;
  }
}
